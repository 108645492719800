:root {
  --dark-khaki: #7c7300;
  --khaki: #bdb76b;
  --black: #000;
  --lighter-grey: #e7e7e7;
  --light-grey: #eee;
  --grey: #ccc;
  --white: #fff;

}

.row-fullscreen {
  width: 100%;
  min-height: calc(100vh - 120px - 68px - 50px);
  height: auto;
  background-color: #faf9f9;
  display: flex;
  flex-direction: row;

}

footer {
  display: flex;
  flex-direction: column;
  height: 50px;
  line-height: 45px;
  vertical-align: middle;
  align-items: center;
  background-color:#767676;
  color: #fff;
}

.container-product,
.container-cart {
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
  margin: 20px;
  display: block;
  padding: 0;
  overflow: hidden;
}

.container-product {
  width: auto;
}

.container-cart {
  width: 300px;
  flex: none;
}

.container-main-header {
  border-bottom: 1px solid #ccc;
  
  padding: 10px 0;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: #000;
}

h6 {
  margin-bottom: 0;
}

header {
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 0 15px 1px var(--black);
  background-color: var(--khaki) !important;
}

.txt-strike {
  text-decoration: line-through;
  color: #939393 !important;
}

.btn-small {
    padding: 2px 10px;
}

.admin-edit {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    font-size: 40px;
    background-color: var(--white);
    border: 2px solid var(--black);
    border-radius: 5px;
    box-shadow: 0px 0px 15px 5px #000;
    color: var(--red);
    cursor: pointer;
}

.bg-yellow {
  background-color: #ffcc3c40;
}

.bg-green {
  background-color: #0b9b2347;
}

.bg-dark-khaki {
  background-color: var(--khaki);
}

.logo-holder img {
  height: 120px;
}

.logo-bg {
  background-color: #f7f7f7 !important;
}

input[type="text"],
input[type="password"],
textarea,
select,
option {
    border: 1px solid #aaa;
    border-radius: 5px;
    padding: 8px 10px;
    margin: 2px auto;
    width: 100%;
    outline: none;
    color: #999;
    font-weight: normal;
}

textarea {
    height: 180px;
}

label {
    color: #000;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.5rem;
    margin-bottom: 0.2rem;
}

.txt-red {
  color: rgb(107, 0, 0);
}

.menu-bar {
  /* box-shadow: 0 0 25px 5px #333; */
  background-color: var(--khaki) !important;
  /* border: 1px solid #ccc; */
  border-left: none;
  border-right: none;
}

.navbar-nav a {
  padding: 4px 20px !important;
  background-color: #ccc;
  color: #000 !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.25rem;
}

.dropdown-toggle {
  padding: 4px 20px !important;
  /* background-color: #ccc; */
  color: #000 !important;
  font-weight: bold;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 8px;
  border: 2px solid var(--black);
  border-radius: 15px;
  /* font-weight: bold; */
  /* font-size: 1.25rem; */
}

.navbar-nav a {
  padding: 14px 20px !important;
  background-color: transparent;
  color: var(--black) !important;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1rem;
}

.navbar-nav a.active {
  background-color: var(--dark-khaki);
  color: var(--white) !important;
  
}

.checkbox {
  width: 25px;
  height: 25px;
  display: inline-block;
}

.product-holder,
.cart-product-holder {
  /* border: 2px solid #ccc;
  border-radius: 15px;
  overflow: hidden; */
  box-shadow: 5px 5px 5px #ccc;
}

.product-title {
  /*background-color: #ccc;*/
  border-top: 1px solid rgba(0,0,0,.125);
  border-bottom: 1px solid rgba(0,0,0,.125);
  font-size: 14px;
  font-weight: bold;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-short-text {

  height: 120px;
  overflow: hidden;
  font-size: 14px;
}

.product-price {
  border-top: 2px dashed rgba(0,0,0,.125);
  border-bottom: 2px dashed rgba(0,0,0,.125);
  background-color: #f8f6f6;
  text-align: center;

}

.price-title {
  color: #666;
  font-weight: bold;

}

.price-value {
  color: #f00;
  font-weight: bold;
}

.cart-product-title {
  font-weight: bold;
  font-size: 14px;
  background-color: #ccc;
  overflow: hidden;
  
}

.cart-product-img {
  width: 80px;
}

.price-div {
  display: inline-block;
  width: auto;
}

.cart-total-div {
  border: 3px solid #aaa; 
  border-radius: 10px;  
  box-shadow: 0px 0px 5px 5px #ccc;
}

.cart-total-div h3 {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

.pic-border-shadow {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 5px #ccc;
}

.fit-cover {
  object-fit: cover;
  align-self: center;
  flex: 0 0 auto;
}

.border {
  border: 1px solid #ccc;
}

.card-img-holder {
  height: 250px;
}